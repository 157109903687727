import React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import ResourcesPageContent from "../components/ResourcesPageContent"

const props = {
  title: "Links and Resources",
  description: "Helpful links and resources:",
  resources: [
    {
      name: "Ontario Provincial Police",
      phone: "807-737-2020 / 1-888-310-1122",
      link: {
        text: "opp.ca",
        url: "https://opp.ca/",
      },
    },
    {
      name: "Meno-Ya-Win Sioux Lookout Health Centre",
      phone: "807-737-3030",
      link: {
        text: "slmhc.on.ca",
        url: "https://slmhc.on.ca/",
      },
    },
    {
      name: "Assault Care & Treatment Program (ACT)",
      phone: "807-737-6565",
      link: {
        text: "slmhc.on.ca",
        url:
          "https://slmhc.on.ca/departments-and-services/assault-care-and-treatment/",
      },
    },
    {
      name: "Victim Witness Assistance Program (VWAP)",
      phone: "807-223-7310",
      link: {
        text: "attorneygeneral.jus.gov.on.ca",
        url:
          "https://www.attorneygeneral.jus.gov.on.ca/english/ovss/VWAP-English.html",
      },
    },
    {
      name: "First Step Women’s Shelter",
      phone: "807-737-1438 / 1-800-465-3623 ",
      link: {
        text: "fsws.ca",
        url: "http://www.fsws.ca/",
      },
    },
    {
      name: "Nahnahdah-Wee-ee-Waywin Centre & Counselling Services",
      phone: "807-737-4848 / 1-833-864-4848",
      link: {
        text: "slsac.ca",
        url: "http://www.fsws.ca/slsac",
      },
    },
    {
      name: "NAN Victim Witness Liaison Program (Sioux Lookout)",
      phone: "807-737-8065",
      link: {
        text: "nanlegal.on.ca/victim-witness-liaison/",
        url: "https://nanlegal.on.ca/victim-witness-liaison/",
      },
    },
    {
      name: "Assaulted Women’s Helpline",
      phone: "1-866-863-0511",
      link: {
        text: "awhl.org",
        url: "https://www.awhl.org/",
      },
    },
    {
      name: "Canadian Human Trafficking Hotline",
      phone: "1-833-900-1010",
      link: {
        text: "canadiancentretoendhumantrafficking.ca",
        url: "https://www.canadiancentretoendhumantrafficking.ca/",
      },
    },

    {
      name: "Kids Help Phone",
      phone: "1-800-668-6868",
      link: {
        text: "https://kidshelpphone.ca/",
        url: "https://kidshelpphone.ca/",
      },
    },
    {
      name: "24/7 Canada Suicide Prevention Service",
      phone: "1-833-456-4566",
      link: {
        text: "crisisservicescanada.ca",
        url: "https://www.crisisservicescanada.ca/",
      },
    },
    {
      name: "Canadian Red Cross",
      phone: "807-223-4751 (Dryden) / 800-363-7305 (Canada Wide)",
      link: {
        text: "redcross.ca",
        url: "https://www.redcross.ca/",
      },
    },
    {
      name: "Lac Seul First Nation Police Service",
      phone: "807-582-3802",
      link: {
        text: "",
        url: "",
      },
    },
  ],
}
const Resources = () => {
  return (
    <Layout>
      <SEO title="Links and Resources" />
      <ResourcesPageContent {...props} />
    </Layout>
  )
}

export default Resources
