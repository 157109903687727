import React from "react"
import classNames from "classnames"
import Wrapper from "../Wrapper"
import Row from "../Row"
import Column from "../Column"

import { Body, BodyLarge, H2 } from "../Typography"

import styles from "./index.module.scss"
import Link from "../Link"

type Props = {
  title: string
  description: string
  resources: Array<{
    name: string
    phone: string
    link: {
      text: string
      url: string
    }
  }>
}

const ResourcesPageContent = ({ title, description, resources }: Props) => (
  <Wrapper className={styles.pageContent} component="section">
    <Row center>
      <Column mobile={8} desktop={14}>
        <H2 className={styles.title} component="h1">
          {title}
        </H2>
        <BodyLarge>{description}</BodyLarge>
        <Row center className={styles.titleRow}>
          <Column mobile={8} desktop={5} className={styles.columnCell}>
            <Body id="resourceName" fontWeight="bold">
              Name
            </Body>
          </Column>
          <Column mobile={8} desktop={5} className={styles.columnCell}>
            <Body id="resourcePhone" fontWeight="bold">
              Phone
            </Body>
          </Column>
          <Column mobile={8} desktop={6} className={styles.columnCell}>
            <Body id="resourceLink" fontWeight="bold">
              Link
            </Body>
          </Column>
        </Row>
        {resources.map((resource, i) => (
          <Row center className={styles.itemRow} key={`resource-${i}`}>
            <Column mobile={8} desktop={5} className={styles.columnCell}>
              <Body fontWeight="bold" className={styles.mobileTitle}>
                Name
              </Body>
              <Body
                aria-labelledby="resourceName"
                className={styles.resourcesName}
              >
                {resource.name}
              </Body>
            </Column>
            <Column
              mobile={8}
              desktop={5}
              className={classNames(styles.columnCell, styles.columnCellPhone)}
            >
              <Body fontWeight="bold" className={styles.mobileTitle}>
                Phone
              </Body>
              <Body aria-labelledby="resourcePhone">{resource.phone}</Body>
            </Column>
            <Column mobile={8} desktop={6} className={styles.columnCell}>
              {resource.link.url && (
                <>
                  <Body fontWeight="bold" className={styles.mobileTitle}>
                    Link
                  </Body>
                  <Link
                    to={resource.link.url}
                    aria-labelledby="resourceLink"
                    className={styles.resourcesLink}
                  >
                    {resource.link.text}
                  </Link>
                </>
              )}
            </Column>
          </Row>
        ))}
      </Column>
    </Row>
  </Wrapper>
)

export default ResourcesPageContent
